import http from './http-common'

export async function getAllMallBanners () {
  return await http.get('/staff/mall-banner/get-all').then(response => response.data.data)
}

export async function createMallBanner (data) {
  return await http.post('/staff/mall-banner/create', data)
}

export async function editMallBanner (id, data) {
  return await http.put(`/staff/mall-banner/update/${id}`, data)
}

export async function deleteMallBanner (id) {
  return await http.delete(`/staff/mall-banner/delete/${id}`)
}

export async function enableMallBanner (id) {
  return await http.put(`/staff/mall-banner/enable/${id}`)
}

export async function disableMallBanner (id) {
  return await http.put(`/staff/mall-banner/disable/${id}`)
}
