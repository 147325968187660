<script>
import DeliveryFee from './settings/DeliveryFee.vue'
import ShoppingGuide from './settings/ShoppingGuide.vue'
import WhatAppUrl from './settings/WhatAppUrl.vue'
import MallBanner from './settings/MallBanner.vue'

export default {
  components: {
    DeliveryFee,
    ShoppingGuide,
    WhatAppUrl,
    MallBanner
  }
}
</script>
<template>
  <b-container fluid>
    <div class="card border-0 shadow-sm mb-4 pb-4">
    <b-tabs content-class="mt-3" fill pills>
        <b-tab title="快遞費" active><DeliveryFee /></b-tab>
        <b-tab title="購物指南"><ShoppingGuide/></b-tab>
        <b-tab title="WhatsApp URL"><WhatAppUrl/></b-tab>
    </b-tabs>
    </div>
    <MallBanner></MallBanner>
  </b-container>
</template>
<style>
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background: #9f836e !important;
  border: #9f836e !important;
  color: white !important;
  border: 1px solid #e6d9d1  !important;
}
.nav-pills .nav-link {
  background: white!important;
  border: white !important;
  color: #9f836e  !important;
  border: 1px solid #e6d9d1  !important;
  font-size: 14px;
  font-weight: 500;

}
.nav .nav-pills .nav-fill {
  padding: 1.5rem  ;
  margin-bottom: 1.5rem !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
</style>
