<script>
import UploadIcon from '../../components/icons/UploadIcon.vue'
import TableData from '../../components/TableData.vue'
import { createMallBanner, deleteMallBanner, disableMallBanner, editMallBanner, enableMallBanner, getAllMallBanners } from '../../services/mall-banner.service'

export default {
  components: { TableData, UploadIcon },
  data () {
    return {
      id: '',
      title: '',
      description: '',
      uploadedImage: '',
      file: '',
      mallBanners: [],
      isSubmit: false,
      isEdit: false,
      fileIsNull: false,
      headers: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Image',
          value: 'imageUrl'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: '',
          value: 'menu'
        }
      ]
    }
  },
  async mounted () {
    this.mallBanners = await getAllMallBanners()
  },
  methods: {
    onUploadFile () {
      if (this.$refs.file) this.$refs.file.click()
    },
    onUploadedFile () {
      const file = this.$refs.file
      const fileReader = new FileReader()

      fileReader.onload = (p) => {
        this.clearBlob()
        this.uploadedImage = p.target.result
        this.fileIsNull = false
      }

      if (file.files) {
        this.file = file.files[0]
        fileReader.readAsDataURL(file.files[0])
      }
    },
    clearBlob () {
      if (this.uploadedImage) URL.revokeObjectURL(this.uploadedImage)
    },
    async onSubmit () {
      this.isSubmit = true
      this.$validator.validateAll().then(async result => {
        if (!this.uploadedImage) {
          this.fileIsNull = true
          this.isSubmit = false
          return
        }
        if (!result) {
          this.isSubmit = false
          return
        }

        const data = new FormData()

        data.set('description', this.description)
        data.set('title', this.title)

        if (!this.isEdit) {
          data.set('file', this.file)

          await createMallBanner(data).then(
            async response => {
              if (response.data.success) {
                this.mallBanners = await getAllMallBanners()
                this.makeToast('success', 'Creating new mall banner successfully!')
                this.$bvModal.hide('modal')
              } else this.makeToast('danger', 'Creating new mall banner is not successful!')
            }
          )
        } else {
          if (typeof this.file === 'object') data.set('file', this.file)

          await editMallBanner(this.id, data).then(
            async response => {
              if (response.data.success) {
                this.mallBanners = await getAllMallBanners()
                this.makeToast('success', 'Editing mall banner successfully!')
                this.$bvModal.hide('modal')
              } else this.makeToast('danger', 'Editing mall banner is not successful!')
            }
          )
        }
        this.isSubmit = false
      })
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    onCreate () {
      this.isEdit = false
      this.clearForm()
    },
    onEdit (item) {
      this.isEdit = true
      this.id = item.id
      this.title = item.title
      this.description = item.description
      this.uploadedImage = item.imageUrl
    },
    onDelete (id) {
      deleteMallBanner(id).then(async response => {
        if (response.data.success) {
          this.mallBanners = await getAllMallBanners()
          this.makeToast('success', 'Deleting mall banner successfully!')
          this.$bvModal.hide('modal')
        } else this.makeToast('danger', 'Deleting mall banner is not successful!')
      })
    },
    onEnable (id) {
      enableMallBanner(id).then(async response => {
        if (response.data.success) {
          this.mallBanners = await getAllMallBanners()
          this.makeToast('success', 'Enable mall banner successfully!')
          this.$bvModal.hide('modal')
        } else this.makeToast('danger', 'Enable mall banner is not successful!')
      })
    },
    onDisable (id) {
      disableMallBanner(id).then(async response => {
        if (response.data.success) {
          this.mallBanners = await getAllMallBanners()
          this.makeToast('success', 'Disable mall banner successfully!')
          this.$bvModal.hide('modal')
        } else this.makeToast('danger', 'Disable mall banner is not successful!')
      })
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    clearForm () {
      this.id = ''
      this.description = ''
      this.title = ''
      this.uploadedImage = ''
      this.file = ''
    }
  }
}
</script>
<template>
  <div class="bg-white px-3 pt-3 pb-1 rounded shadow-sm">
    <h5 class="pb-1">Mall banners management</h5>
    <b-button class="rounded-md bg-primary mb-3 add-btn" @click="onCreate()" v-b-modal.modal>Create new mall banner</b-button>

    <b-modal size="xl" id="modal" title="Create new mall banner" centered>
      <div class="row">
        <div class="col-5">
          <img
            @click="onUploadFile()"
            v-if="uploadedImage"
            class="w-100 rounded cr-pointer border-dashed"
            :src="uploadedImage"
            alt="uploaded-photo"
          />

          <div @click="onUploadFile()" v-else class="bg-gray-2 d-flex justify-content-center rounded">
            <UploadIcon class="cr-pointer upload-btn rounded border-dashed"></UploadIcon>
          </div>
          <input ref="file" type="file" class="d-none" @input="onUploadedFile()">

          <div style="height: 20px;" class="text-danger">
            {{ fileIsNull ? "Image is required file" : '' }}
          </div>
        </div>

        <div class="col-7">
          <div class="mb-2">
            <label class="label" for="title">Enter title:</label>

            <b-input
              placeholder="Title"
              name="title"
              v-model="title"
              v-validate="{ required: true }"
              :state="validateState('title')"
            ></b-input>
          </div>

          <label class="label" for="description">Enter description:</label>
          <b-textarea
            placeholder="Description"
            name="description"
            v-model="description"
            v-validate="{ required: true }"
            no-resize
            rows="10"
            :state="validateState('description')"
          ></b-textarea>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button class="add-btn bg-primary" style="width: 100px;" block @click="onSubmit" v-if="!isSubmit">
          Add
        </b-button>

        <b-button class="add-btn bg-primary" style="width: 100px;" block v-else>
          <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
        </b-button>
      </template>
    </b-modal>

    <table-data :headers="headers" :items="mallBanners" disableKey="active">
      <template #custom-imageUrl="{value}">
        <img :src="value" alt="mall-banner-image" class="rounded" style="width: 150px;">
      </template>

      <template #custom-active="{value}">
        <div v-if="value" class="text-success font-weight-bold">
          Activating
        </div>

        <div v-else class="text-danger font-weight-bold">
          Deactivated
        </div>
      </template>

      <template #custom-menu="{item}">
        <b-dropdown variant="link" toggle-class="text-dark text-decoration-none" no-caret>
          <template #button-content>
            <b-icon icon="three-dots-vertical" font-scale="1"></b-icon>
          </template>

          <b-dropdown-item @click="onEdit(item)" v-b-modal.modal>Edit</b-dropdown-item>
          <b-dropdown-item @click="onDelete(item.id)">Delete</b-dropdown-item>
          <b-dropdown-item @click="onEnable(item.id)">Enable</b-dropdown-item>
          <b-dropdown-item @click="onDisable(item.id)">Disable</b-dropdown-item>
        </b-dropdown>
      </template>
    </table-data>
  </div>
</template>

<style scoped>
.add-btn {
  font-size: 16px;
  width: max-content;
  cursor: pointer;
}

.modal-wrapper {
  position: absolute;
  width: screen;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
}

.cr-pointer {cursor: pointer;}

.upload-btn {
  padding: 20px;
  border: 10px;
  background: rgba(128, 128, 128, 0.2);
  color: gray;
  height: 350px;
  padding: 50px;
  width: 100%;
}

.border-dashed {border: 3px dashed gray;}

.label {font-size: 16px;}
</style>
